/*globals*/

/* --------------------------------------- */
/* BASIC SETUP */
/* --------------------------------------- */

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  background-color: #ffffff;
  color: #555;
  font-family: 'Lato', 'Arial', 'sans-serif';
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
  overflow-x: hidden;
  --vblue: #0068B0;
  --vgray: #BABABB;
  
}

.clearfix {zoom: 1}
.clearfix:after {
  content: '.';
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}



.row{
  max-width: 100%;
  margin: 0 auto;
  
}


img { 
  display: inline-block;
}


section {
  padding: 80px 0;
  margin: 0;
  
}

.box {
  padding: 1%;
}
/*------------*/
/*  headings  */
/*------------*/
h1,
h2,
h3 {
  font-weight: 300;
  text-transform: uppercase;
 
  
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;
  /* color: #fff; */
  font-size: 240%;
  word-spacing: 4px;
  letter-spacing: 1px;
}

h2 {
  font-size: 180%;
  word-spacing: 2px;
  text-align: center;
  margin-bottom: 30px;
  letter-spacing: 1px;
  width: 100%;
}

h3 {
  font-size: 110%;
  margin-bottom: 15px;
  
}

h2:after {
  display: block;
  height: 2px;
  background-color: #0068B0;
  content: "  ";
  width: 100px;
  margin: 0 auto;
  margin-top: 30px;
}

/* div > div> div ul {
  list-style: none;
  }
  
div> div > div ul:before {
      display: block;
  height: 2px;
  background-color: #0068B0;
  content: "  ";
  width: 30%;
  margin: 20px auto;
  margin-top: 30px;
  }
div > div > div li:before {
  content: "\2022";
  color: #0068B0;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  
} */



/*------------*/
/*  Animatie   */
/*------------*/


@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  100% {
    opacity: 1;
    transform: translate(0); } }


@keyframes moveInTop {
  0% {
      opacity: 0;
      transform: translateY(-100rem); }
  100% {
      opacity: 1;
      transform: translate(0); } }


@keyframes doeOut {
  0% {
      opacity: 0;
      transform: scale(9,9);
      
  }
  100% {
      opacity: 1;
      /* transform: scale(0.0); */
      
  }
}
  @keyframes doeAan {
    0% {
        opacity: 0;
        transform: scale(9,9);
        
    }
    100% {
        opacity: 1;
        /* transform: scale(0.0); */
        
    }
}








/*------------*/
/*  Buttons   */
/*------------*/

.btn:link,
.btn:visited,
input[type=submit] {
  display: inline-block;
  padding: 10px 30px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 200px;
  -webkit-transition: background-color 0.2s, border 0.2s, color 0.2s;
  transition: background-color 0.2s, border 0.2s, color 0.2s;
}

.btn-full:link,
.btn-full:visited,
input[type=submit] {
  
  display: inline-block;
  padding: 10px 30px;
  font-weight: 300;
  text-decoration: none;
  border-radius: 200px;
  background-color: var(--vblue);
  border: 1px solid var(--vblue);
  color: #fff;
  margin-right: 15px;
}
.btn-ghost:link, 
.btn-ghost:visited {
  border: 1px solid var(--vblue);
  color: var(--vblue);
}
.btn:hover,
.btn:active,
input[type=submit]:hover,
input[type=submit]:active {
  background-color: var(--vblue);
}

.btn-full:hover,
.btn-full:active {
/*
  border: 1px solid var(--vgray);
   background-color: var(--vgray);
*/
  opacity: 0.8;
  transform: scale(0.9,0.9);
}
.btn-ghost:hover, 
.btn-ghost:active {
  border: 1px solid var(--vblue);
  color: #fff;
}



header {
  
  height: 80vh;
  background-color: #bbb7b7;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  
}

/* .header-image {
  background-image: -webkit-gradient(linear,left top, left bottom,from(rgba(0, 0, 0, 0.7)),to(rgba(0, 0, 0, 0.7))), url(img/screenshot.png);
  background-image: linear-gradient(rgba(0, 0, 0, 0.1),rgba(0, 0, 0, 0.1)), url(img/screenshot.png);
  /* animation-name: doeOut;
  animation-duration: 3s; } 
*/


body {
  height: 100%;
  margin: 0;
  
}


/* .hero-text-box {
  position: absolute;
  width: 72%;
  font-size: 66%;
  top: 57%;
  left: 13%;
  background-color: rgba(0, 103, 176, 0.089);
  text-align: center;
  padding: 3%;
  animation-name: moveInLeft;
  animation-duration: 4s;
  font-size: 200%;
} */

/*--------------------------------*/
/*         Navigatie              */
/*--------------------------------*/


/*--------------------------------*/
/*        Contact dialog          */
/*--------------------------------*/

.contact-dialog {
  background-color: #f7f7f7;
  color: #000000;
}


.callout-light-foto {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle; 
  -ms-flex-line-pack: center; 
  align-content: center;
  
  
  
}

.callout-light-foto-frame {
  -ms-flex-line-pack: center;
  align-content: center;
  
}


/*--------------------------------*/
/*   Wie wij zijn section         */
/*--------------------------------*/

.section-wieWijZijn {
  width: 100%;
  text-align: justify;
  
}


/*--------------------------------*/
/*  section wat wij doen          */
/*--------------------------------*/

.section-watWijDoen {
  
  text-align: center;
  margin: 0 3%; 
  background-color: #f4f4f4;
 
}
.section-watWijDoen h2 {
  width: 100%
}

/*--------------------------------*/
/*  section with cards            */
/*--------------------------------*/

.section-cards {
  text-align: center;
  
}
.section-cards .card{
  text-align: justify;
  margin: 2%;
  background-color: #f4f4f4;
  font-size: 90%;
  
}
.section-cards .card-footer{
  text-align: center;
  margin: 2%;
  
  
}
.section-cards h2 {
  width: 100%;
}
.section-cards p:first {
  padding: 130px;
  margin:  130px;
}


/*--------------------------------*/
/*   Algemene Section settings    */
/*--------------------------------*/
.section-p {
 
  /*padding: 10% 10%;*/
  line-height: 145%;
  width: 70%;
  margin-left: 15%; 
  margin-bottom: 11%;
  text-align: center;
}


.icon-big {
  font-size: 112%;
  color: var(--vblue);
}



.icon-small {
  color: var(--vblue);
  
}

.section-callout-light {
    text-align: center;
    /* background-color: #f4f4f4; */
}

.section-callout-light h3 {
  width: 100%;
  text-align: center;
}
.section-callout-light p:first {
  padding: 130px;
  margin:  130px;
}
.section-callout-light p {
  padding: 10px;
  margin-left: 15%; 
  margin-right: 15%;
  margin-top: 3%;
  text-align: center;
}




.section-callout-dark {
  text-align: center;
  background-color: #f4f4f4;
}

.section-callout-dark h3 {
width: 100%;
text-align: center;
}
.section-callout-dark p:first {
padding: 130px;
margin:  130px;
}
.section-callout-dark p {
padding: 10px;
margin-left: 15%; 
margin-right: 15%;
margin-top: 3%;
text-align: center;
}


/*--------------------------------*/
/*         footer section         */
/*--------------------------------*/

.section-footer {
  background-color: var(--vblue);
  color: var(--vgray);
/*    background-color: #3e3e3e;*/
  padding: 20px;
  margin: 0px;
  
  
}
.section-footer p{
   width: 100%;
  text-align: center;
  
}
/*--------------------------------*/
/*         Modal settings         */
/*--------------------------------*/

.modal {
  top: 61px; 
}
.modal-body {
   text-align: justify;
}
.modal-body h5{
  text-align: left;
  margin-top: 2em;
}
/* .modal-body li {
   
} */
.modal-content {
   background-color: #f7f7f7;
   color: #000000;

}
.modal-content img {
  width: 100%;
  display: inline;

}

