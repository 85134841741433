.scroll {
    max-height: 50rem;
    overflow-y: auto;
    
}
.card {
    
    min-width: 22rem;
    /* max-width: 28rem; */
    height: 50rem;
    /* border-color: #0068B0; */
   
    
    
}
