.header-image {
  margin-top: 0px;
  width: 100%;
  height: auto;
  background-color: #bbb7b7;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  
}

.hero-text-box {
  position: absolute;
  /* bottom: 0;  */
  top: 40%;
  background: rgb(0, 103, 176);
  background: rgba(0, 103, 176, 0.877); /* Black see-through */
  color: #f1f1f1;
  width: 100%;
  transition: 0.5s ease;
  /* opacity:0; */
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
}
