.section-p {
   
    /*padding: 10% 10%;*/
    line-height: 145%;
    width: 70%;
    margin-left: 15%; 
    margin-bottom: 11%;
    text-align: center;
}


.icon-big {
    font-size: 112%;
    color: var(#0068B0);
}



.icon-small {
    color: var(#0068B0);
     
    
}

.section-callout-light {
      text-align: center;
      /* background-color: #f4f4f4; */
}

.section-callout-light h3 {
    width: 100%;
    text-align: center;
    font-size: 100%;
}
.section-callout-light p:first {
    padding: 130px;
    margin:  130px;
}
.section-callout-light p {
    padding: 10px;
    margin-left: 1%; 
    margin-right: 1%;
    margin-top: 3%;
    text-align: center;
}




.section-callout-dark {
    text-align: center;
    background-color: #f4f4f4;
}

.section-callout-dark h3 {
  width: 100%;
  text-align: center;
  font-size: 100%;
  
}
.section-callout-dark p:first {
  padding: 130px;
  margin:  130px;
  
}

.section-callout-dark p {
  padding: 10px;
  margin-left: 1%; 
  margin-right: 1%;
  margin-top: 3%;
  text-align: center;
}

.video {
    width: 80%;
    left: 10%;
    margin-top: 10%; 
    height: 80%;
    
    
}
