


/*


-------- copy het onderstaande blok in de html



        <!--ecmpartners logo-->
        <div class="logoSize1">
            <p class="ecmp">ECMP</p>
            <p class="artners">artners</p>
        <div class="bolcol1">
            <div class="bolletjes"></div>
            <div class="bolletjes"></div>
        </div>
        <div class="bolcol2">
            <div class="bolBlue"></div>
            <div class="bolletjes"></div>
        </div>
        </div> 
*/
.logo {
    height: 40px;
    width: 265px;
}

.logoSize1 {
    float:left !important ;
    --vblue: #0068B0;
    --vgray: #BABABB;
    --vheight: 43px;
    --vbolsize: 16px;
    --vFontsize: 38px;
    --vBolMargin: 2px;
    --vbolcol2left: 18px;
    --vecmpleft: 40px;
    --vartnersleft: 146px;
    position: relative;
    height: 40px;
    width: 265px;
    overflow: hidden;
    display: flex;

}


.bolletjes {
    background-color: var(--vblue);
    width: var(--vbolsize);
    height: var(--vbolsize);
    border-radius: 50%;
    content: "v";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    margin: var(--vBolMargin);
    
}

.bolBlue {
    background-color: var(--vgray);
    width: var(--vbolsize);
    height: var(--vbolsize);
    border-radius: 50%;
    content: "v";
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    margin: var(--vBolMargin);
    
}



.ecmp{
    margin: 0;
    line-height: var(--vheight);
    font-size: var(--vFontsize);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    height: var(--vheight);
/*    font-family: "Haettenschweiler";*/
    font-family: 'Anton', sans-serif;
    font-weight: bold;
   
    
    color: var(--vblue);
    position: absolute;
    left: var(--vecmpleft);

    content: "ECMP";

/*    -webkit-transform: scale(1.3,1);*/
/*    transform: scale(1.2,1);*/
        
}
.artners{
    margin: 0;
    line-height: var(--vheight);
    font-size: var(--vFontsize);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    margin-left: 2;
    padding: 0;
    height: var(--vheight);
/*    font-family: "Haettenschweiler";*/
    font-family: 'Anton', sans-serif;
    color: var(--vgray);
    position: absolute;
    left: var(--vartnersleft);
    
/*    transform: scale(0.7,1);*/
        
}


.bolcol2 {
    position: absolute;
    left: var(--vbolcol2left);      
}

.bolcol1 {
     position: absolute;
}
