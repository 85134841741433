/* big tablet to 1200px  for 1025 to 1200px */
@media only screen and (max-width: 1200px){
    
}


/* Small tablet to big tablet for 768 to 1024 */
@media only screen and (max-width: 1023px){
    
    
}


/* Small phones to small tablets for 576 to 767px */
@media only screen and (max-width: 767px){
    
   .logoResponsive{
        transform: scale(0.8,0.8);
    }
    
    .navResponsive{
        /* transform: scale(0.9,0.9); */
        padding: 0.0.0.0;

    }
    .heroText{
        font-size: 50% !important;
        top: 20%;
    }
    .heroResponsive{
        background-image: 30%;
        font-size: 50% !important;
        
        
    }
    }

/* Small phones to small tablets for 481 to 575px */
@media only screen and (max-width: 575px){
    
   .logoResponsive{
        transform: scale(0.7,0.7);
    }
    
    .heroResponsive{
        background-image: 30%;
        font-size: 50% !important;
        
        
    }
    
    }
  
/* Small phones for 0 to 480px */
@media only screen and (max-width: 480px){
    .modal-content {
        font-size: xx-small;
    }

    
    .logoResponsive{
        transform: scale(0.5,0.5);
    }
    
    .navResponsive{
        transform: scale(0.8,0.8);
        padding: 0.0.0.0;
    }

    .heroResponsive{
        background-image: 30%;
        font-size: 50% !important;
       
        
    }
    }
