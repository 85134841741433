.footer{
    background-color: #0068B0;
    color: white;
    height: 90px;
    position: relative;
    text-align: center;


}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }